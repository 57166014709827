import React from 'react';

// eslint-disable-next-line
import imgHeaderBg from './images/netphase-hdr-bg.jpg';
// eslint-disable-next-line
import imgFooterBg from './images/footer-bg.jpg';
import imgLogo from './images/netphase-logo.png';
import imgGirlShopping from './images/girl-shopping.jpg';
import imgBusnApp from './images/busn-app.png';
import imgUserCentric from './images/user-centric.png';
import imgMobileApp from './images/mobile-app.png';
import imgInfrastructure from './images/infrastructure.png';
import imgProjectRescue from './images/project-rescue.png';
import imgStrategyConsult from './images/strategy-consult.png';
import './Legacy.scss';

const App: React.FC = () => {
  return (
<div id="homepage">
	<div className="header">
		<div className="container-12">
			<div className="mojo">
				<h3>Web + Mobile + Infrastructure</h3>
				Enterprise Experience.  Agile Delivery.
			</div>
			<div className="logo">
				<img src={imgLogo} alt="logo"/>
			</div>
		</div>
	</div>

	<div className="content">
		<div className="lead-content">
			<div className="container-12">
				<img src={imgGirlShopping} alt="girl shopping"/>
				<h1>Is Your Business Ready For The Mobile Generation?</h1>

				<p>Are your employees able to access your company's systems while on the go? Are your customers getting the best mobile experience from your site? Do you have a strategy to tap into the millions of new iPhone, Android and other smart phone users that are flocking to the mobile web every month?</p>
			</div>
		</div>
		<div className="main-content">
			<div className="container-12 what-we-do group">
				<h1>What We Do</h1>

				<div>
					<img src={imgBusnApp} alt="business app"/>
					<h4>Business Applications</h4>
					<p>Strong technical expertise combined with broad subject matter experience means the right solution for your enterprise business application.</p>
				</div>

				<div>
					<img src={imgUserCentric} alt="user centric"/>
					<h4>User-centric Websites</h4>
					<p>User frustration is the number one reason websites fail. We specialize in easy-to-use, accessible sites that won't turn your customers away.</p>
				</div>

				<div>
					<img src={imgMobileApp} alt="mobile app"/>
					<h4>iPhone/Mobile Apps</h4>
					<p>There will be more than 80 million iPhone and iPod Touch owners by the end of 2010. Think your company doesn't need an iPhone app? Think again.</p>
				</div>

				<div>
					<img src={imgInfrastructure} alt="infrastructure"/>
					<h4>Systems & Server Infrastructure</h4>
					<p>Cloud Computing gets all the press, but is it right for your business? We design robust, scalable systems for even the most demanding applications.</p>
				</div>

				<div>
					<img src={imgProjectRescue} alt="project rescue"/>
					<h4>Project Rescue</h4>
					<p>Sometimes, even the best intended projects go awry. We are experts in righting the ship, no matter how much it resembles the Titanic.</p>
				</div>

				<div>
					<img src={imgStrategyConsult} alt="strategy consult"/>
					<h4>Strategy &amp; Consulting</h4>
					<p>Our team has a long track record of success on projects of all sizes and scopes for the best of the best. We know how to deliver results.</p>
				</div>


			</div>
		</div>
	</div>

	<div className="footer">
		<div className="footer-content container-12">
  		<div className="copyright">Copyright © 2019 Netphase, LLC. All rights reserved.</div>
		</div>
	</div>
</div>
  );
}

export default App;
